/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {useStaticQuery, graphql} from "gatsby"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import {Theme} from "../../styles/theme"
import {ButtonStyles} from "../../styles/button-styles"

interface Props {
  downloadLimit: number
}

const NewsletterBlock = (props: Props): JSX.Element => {
  const data = useStaticQuery(graphql`
    query FeaturedDocumentQuery {
      pages: allDatoCmsDocument(filter: {showOnHomepage: {eq: true}}) {
        edges {
          node {
            id
            title
            slug
            author
            publicationDate
            showOnHomepage
            meta {
              createdAt
            }
          }
        }
      }
    }
  `)

  const sortedDocuments = data.pages.edges
    .sort(
      (a, b) =>
        Date.parse(b.node.meta.createdAt) - Date.parse(a.node.meta.createdAt),
    )
    .slice(0, props.downloadLimit)

  return (
    <div css={NewsletterBlockStyles}>
      <div className="left">
        <div>
          <h2>Nieuwsbrief</h2>
          <p>
            Wilt u op de hoogte blijven van het laatste nieuws op het gebied van
            externe veiligheid? Hier kunt u zich aanmelden voor de nieuwsbrief
            van Relevant.
          </p>
          <a css={ButtonStyles} href="/main/nieuwsbrief">
            <FontAwesomeIcon icon={["far", "angle-right"]} />
            Aanmelden
          </a>
        </div>
      </div>

      <div className="right">
        <div>
          <h4>Nieuwe Documenten</h4>
          {sortedDocuments.map(item => (
            <div key={item.node.id} css={DocumentLinkStyles}>
              <span className="date">
                {new Date(item.node.meta.createdAt).toLocaleDateString(
                  "nl-NL",
                  {
                    year: "numeric",
                    month: "long",
                    day: "numeric",
                  },
                )}
              </span>
              <div>
                <a href={`/documenten/${item.node.slug}`}>{item.node.title}</a>
                <span className="author">{item.node.author}</span>
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  )
}

export default NewsletterBlock

const NewsletterBlockStyles = css`
  display: flex;
  justify-content: space-between;

  .left,
  .right {
    display: flex;
  }

  .left {
    flex-basis: 60%;
    justify-content: flex-end;
    align-items: center;
    padding: 10rem 5rem;
    background-color: ${Theme.colors.backgrounds.blue};

    > div {
      max-width: 51rem;
      flex-grow: 1;
    }

    p {
      max-width: 45rem;
      margin: 4rem 0;
      font-size: 2rem;
      line-height: 3.4rem;
    }
  }

  .right {
    flex-basis: 40%;
    padding: 5rem 8rem;
    background-color: ${Theme.colors.identity.secondary};
    color: white;

    h4 {
      font-size: 2.2rem;
    }
  }

  @media ${Theme.breakpoints.mediumDown} {
    flex-wrap: wrap;

    .left,
    .right {
      flex-basis: 100%;
    }

    .left {
      text-align: center;

      > div {
        max-width: 100%;

        p {
          margin: 4rem auto;
        }
      }
    }

    .right {
      padding: 5rem 3rem;
    }
  }
`

const DocumentLinkStyles = css`
  max-width: 32rem;
  margin-bottom: 2rem;

  .date {
    font-size: 1.4rem;
    line-height: 1.6rem;
    font-weight: 700;
  }

  a {
    color: white;
    font-size: 2rem;
    line-height: 2.6rem;
  }

  .author {
    margin-left: 1rem;
    font-size: 1.7rem;
    font-weight: 200;
    font-style: italic;
    color: #eaeff0;
  }
`
