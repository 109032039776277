/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import PageWidth from "../page-width"

import {Theme} from "../../styles/theme"
import {ButtonStyles} from "../../styles/button-styles"

interface Props {
  content: string
}
const AboutBlock = (props: Props): JSX.Element => {
  return (
    <div css={AboutBlockStyles}>
      <PageWidth>
        <div className="figures">
          <figure
            className="small"
            style={{
              backgroundImage:
                "url(https://www.datocms-assets.com/37731/1610466102-homepage-about-1.jpg?w=1500)",
            }}
          />
          <figure
            style={{
              backgroundImage:
                "url(https://www.datocms-assets.com/37731/1610466114-homepage-about-2.jpg?w=750)",
            }}
          />
        </div>

        <div className="about">
          <h2>
            Over <span>Relevant</span>
          </h2>

          <div>
            <div dangerouslySetInnerHTML={{__html: props.content}} />

            <a
              css={ButtonStyles({type: "transparent"})}
              href="/main/over-relevant"
            >
              Lees meer
              <FontAwesomeIcon icon={["far", "arrow-right"]} />
            </a>
          </div>
        </div>
      </PageWidth>
    </div>
  )
}

export default AboutBlock

const AboutBlockStyles = css`
  background-color: ${Theme.colors.backgrounds.blue};

  .page-width {
    padding-top: 10rem;
    padding-bottom: 10rem;

    .figures {
      display: flex;
      justify-content: space-between;
      margin-bottom: 8rem;

      figure {
        height: 38rem; // IE11
        height: min(38rem, 28vw);
        flex-basis: 66%;
        background-size: cover;

        &.small {
          margin-right: 3rem;
          flex-basis: 33%;
        }
      }

      @media ${Theme.breakpoints.mediumDown} {
        margin-bottom: 4rem;

        figure {
          flex-basis: 100%;
          height: 40vw;

          &.small {
            display: none;
          }
        }
      }
    }

    .about {
      display: flex;
      justify-content: space-around;
      align-items: center;
      flex-wrap: wrap;

      h2 {
        margin-right: 5rem;

        span {
          display: block;
          color: ${Theme.colors.identity.primary};

          @media ${Theme.breakpoints.mediumDown} {
            display: inline;
          }
        }
      }

      p {
        margin-bottom: 0.5rem;
        max-width: 46rem;
        font-size: 2rem;
        line-height: 3.4rem;
      }
    }
  }
`
