/** @jsx jsx */
import {jsx, css} from "@emotion/core"
import {FontAwesomeIcon} from "@fortawesome/react-fontawesome"

import PageWidth from "../page-width"

import {ButtonStyles} from "../../styles/button-styles"

const QuestionFormCtaBlock = (): JSX.Element => {
  return (
    <div css={CtaBlockStyles}>
      <PageWidth>
        <h2>Vraagbaak</h2>
        <p>
          Via de Vraagbaak kunt u vragen stellen over externe veiligheid. Voor
          elk thema is een kennismakelaar aanwezig, die met zijn of haar
          achterban de vraag zal beantwoorden.
        </p>
        <a css={ButtonStyles} href="/main/vraagbaak">
          <FontAwesomeIcon icon={["far", "angle-right"]} />
          Stel vraag
        </a>
      </PageWidth>
    </div>
  )
}

export default QuestionFormCtaBlock

const CtaBlockStyles = css`
  .page-width {
    padding-top: 12rem;
    padding-bottom: 12rem;
    max-width: 65rem;
    text-align: center;

    p {
      margin: 4rem 0 6rem;
      font-size: 2rem;
      line-height: 3.4rem;
    }
  }
`
