/** @jsx jsx */
import {jsx, css} from "@emotion/core"

import PageWidth from "../page-width"

import {Theme} from "../../styles/theme"

const ProvinceLogoBlock = (): JSX.Element => {
  const provinces = [
    {name: "drenthe", url: "https://drenthe.nl"},
    {name: "flevoland", url: "https://flevoland.nl"},
    {name: "friesland", url: "https://friesland.nl"},
    {name: "gelderland", url: "https://gelderland.nl"},
    {name: "groningen", url: "https://provinciegroningen.nl"},
    {name: "limburg", url: "https://limburg.nl"},
    {name: "noord-brabant", url: "https://brabant.nl"},
    {name: "noord-holland", url: "https://noord-holland.nl"},
    {name: "overijssel", url: "https://overijssel.nl"},
    {name: "utrecht", url: "https://provincie-utrecht.nl"},
    {name: "zeeland", url: "https://zeeland.nl"},
    {name: "zuid-holland", url: "https://zuid-holland.nl"},
  ]

  return (
    <div css={ProvinceLogoBlockStyles}>
      <PageWidth>
        {provinces.map(province => (
          <a
            key={province.name}
            rel="noreferrer"
            target="_blank"
            href={province.url}
          >
            <figure
              style={{
                backgroundImage: `url(/images/provincie-logo-${province.name}.svg)`,
              }}
            />
          </a>
        ))}
      </PageWidth>
    </div>
  )
}

export default ProvinceLogoBlock

const ProvinceLogoBlockStyles = css`
  .page-width {
    display: flex;
    padding-top: 12rem;
    justify-content: space-between;
    flex-wrap: wrap;
    @media ${Theme.breakpoints.mediumDown} {
      justify-content: center;
    }

    a {
      margin: 0 1rem;
      flex-basis: calc((100% / 6) - 2rem);
      min-width: 14rem;
      transition: 0.15s opacity ease-in-out;
      &:hover {
        opacity: 0.6;
      }

      figure {
        width: 100%;
        height: 9rem;
        background-repeat: no-repeat;
        background-position: center;
      }
    }
  }
`
