/** @jsx jsx */
import {jsx} from "@emotion/core"
import {PageProps} from "gatsby"

import {Page} from "../../types"

import Layout from "../components/layout"
import BannerGroup from "../components/banner-group"
import NewsBlock from "../components/blocks/news-block"
import AboutBlock from "../components/blocks/about-block"
import QuestionFormCtaBlock from "../components/blocks/question-form-cta-block"
import NewsletterBlock from "../components/blocks/newsletter-block"
import ProvinceLogoBlock from "../components/blocks/province-logo-block"

const Home = (page: PageProps): JSX.Element => {
  const data = page.pageContext as Page

  return (
    <Layout title={data.title} currentPage={data}>
      <BannerGroup banners={data.header} size="large" />
      <NewsBlock limit={6} />
      <AboutBlock content={data.content} />
      <QuestionFormCtaBlock />
      <NewsletterBlock downloadLimit={3} />
      <ProvinceLogoBlock />
    </Layout>
  )
}

export default Home
